import "semantic-ui-css/semantic.min.css";
import Form from "@rjsf/semantic-ui";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from 'uuid';

const YAML = require('js-yaml');

  
const schema = {
    "title": "Magma Detection Rule",
    "description": "Schema for detection rules / L3 Magma rules for automation",
    "properties": {
        "title": {
            "title": "Detection (L3 Use-case) Title",
            "description": "Title of the detection rule use-case like it will appear in the alert (in Jira)",
            "type": "string"
        },
        "uuid": {
            "title": "Unique identifier for the use-case",
            "description": "This an UUID Gen 4 (you can use https://www.uuidgenerator.net/version4 to generate UUIDs) or ALT+SHIFT+U under Visual Studio Code",
            "type": "string",
            "format": "uuid",
            "pattern": "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$"
        },
        "type": {
            "title": "Type of this file",
            "description": "What does this UC describe? This is a reference to a detection use-case.",
            "type": "string",
            "enum": [
                "detection"
            ],
            "default": "detection"
        },
        "status": {
            "title": "Status of the use-case",
            "description": "Define the status according to use case development life cycle process",
            "type": "string",
            "enum": [
                "1-SELECTED",
                "2-DESIGN",
                "3-DEV",
                "4-IMPROVING",
                "5-STAGING",
                "6-PRODUCTION",
                "7-UNDER REVIEW",
                "8-DISABLED",
                "9-REMOVED"
            ],
            "default": "3-DEV"
        },
        "priority": {
            "title": "The priority of the use-case",
            "description": "Priority based on a scale from 1 to 10.",
            "type": "number",
            "minimum": 1,
            "maximum": 10,
            "default": 1
        },
        "description": {
            "title": "Description",
            "description": "Explanation (in English) on what this rule is trying to detect and how it is implemented.",
            "type": "string"
        },
        "author": {
            "title": "Author of the detection rule",
            "description": "Email address of the author",
            "type": "string",
            "format": "email"
        },
        "references": {
            "title": "References for the detection rule",
            "description": "External references that explains how the detection works or a reference to the source where the detection rule was found.",
            "type": "array",
            "items": {
                "type": "string",
                "format": "uri-reference"
            }
        },
        "detection": {
            "title": "Detection section",
            "description": "Contains the detection rules and other specifications of the detection",
            "type": "object",
            "properties": {
                "scheduling": {
                    "title": "Scheduling",
                    "description": "When the search needs to execute expressed in hours. Default value = 1h (every 1 hour).",
                    "type": "string",
                    "enum": [
                        "5m",
                        "10m",
                        "15m",
                        "30m",
                        "1h",
                        "24h"
                    ],
                    "default": "1h"
                },
                "timeframe": {
                    "title": "Timeframe",
                    "description": "How long in the past the search needs to go in hours. Default same as scheduling (value = 1h).",
                    "type": "string",
                    "default": "1h",
                    "pattern": ".*h$"
                },
                "throttling": {
                    "title": "Throttling",
                    "description": "How long do discard new alerts that have the same characteristics (duplicate alerts), based on the fields defined below. Default same as scheduling (value = 1h).",
                    "type": "string",
                    "default": "1h",
                    "pattern": ".*h$"
                },
                "threshold": {
                    "title": "Threshold",
                    "description": "If amount of events is higher than threshold (during the timeframe) the alert is triggered. Default = 0.",
                    "type": "integer",
                    "default": 0
                },
                "alert": {
                    "title": "Detection rules",
                    "description": "Detection rule specific for each SIEM",
                    "type": "object",
                    "properties": {
                        "spl": {
                            "description": "Correlation search in spl language (without indexes defined, they are defined above)",
                            "type": "string"
                        },
                        "sentinel": {
                            "description": "Scheduled query in KQL language",
                            "type": "string"
                        },
                        "cbc": {
                            "description": "Carbon black query IOC",
                            "type": "string"
                        },
                        "ids": {
                            "description": "Suricata detection rule",
                            "type": "string"
                        }
                    },
                    "additionalProperties": false
                }
            },
            "additionalProperties": false,
            "required": ["alert"]
        },
        "logsource": {
          "title": "Logsources used for detection",
          "description": "Contains different elements depending on the SIEMs used in the detection rules.",
          "type": "object",
          "properties": {
                "index": {
                  "title": "Splunk index",
                  "description": "Splunk index to used in the correlation search below",
                  "type": "string"
                },
                "source_type": {
                  "title": "Splunk sourcetype",
                  "description": "Splunk sourcetype to use in the correlation search below",
                  "type": "string"
                },
              "connectorId": {
                  "title": "Sentinel connectorId",
                  "description": "Sentinel connectorId that needs to be active for the detection",
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
              },
              "dataTypes": {
                  "title": "Sentinel datatypes",
                  "description": "Sentinel Datatypes needed for the detection",
                  "type": "string"
              }
          }
        },        
        "fields": {
            "title": "Fields to extract",
            "description": "Important fields that needs to be extracted from the alert. Shown to the analyst during alert triage and of interest when escalating to an incident. Use to define duplicate alerts in throttling. List of fields TBD (ex. Microsoft Sentinel entity types reference | Microsoft Docs, or based on Splunk datamodels )",
            "type": "array",
            "items": {
                "type": "string"
            }
        },
        "falsepositives": {
            "title": "Known false positives",
            "description": "In english: explanation of known false-positives that couldn't be removed in the query automatically.",
            "type": "string"
        },
        "tags": {
            "title": "Tags",
            "description": "Tags related to the use-case. Magma tag is mandatory, because it is used to link with L2 use-case and playbook. Fill in with values from the L2 use case page.",
            "type": "object",
            "properties": {
                "magma": {
                    "title": "Magma L2 reference",
                    "description": "magma L2 use-case reference (ex. RE-BRU)",
                    "type": "string",
                    "pattern": "\\w{2}-\\w{3}$"
                },
                "mitre_attack": {
                    "title": "Mitre attack technique",
                    "description": "mitre att&ck technique or sub-technique if relevant (ex. T1070.001)",
                    "type": "string",
                    "pattern": "T\\d{4}.*"
                }
            },
            "required": ["magma"]
        },
        "test": {
            "title": "Test section",
            "description": "Define automated or manual steps to trigger the alert (to provide evidence that the rule works).",
            "type": "object",
            "properties": {
                "description": {
                    "title": "Test description",
                    "description": "Small description of the test (could be referenced)",
                    "type": "string"
                },
                "platform": {
                    "title": "Testing platform (Operating system)",
                    "description": "On which platform (windows, linux,...) to execute the test",
                    "type": "string"
                },
                "language": {
                    "title": "Programming language",
                    "description": "Programming language used in the command below",
                    "type": "string"
                },
                "cmd": {
                    "title": "Command",
                    "description": "Command to execute the test and trigger the alert",
                    "type": "string"
                }
            }
        }
    },
    "allOf": [
      {
        "if": {
          "properties": {
            "detection": {
              "type": "object",
              "properties": {
                "alert": {
                  "type": "object",
                  "properties": {
                    "spl": {
                      "type": "string",
                      "contains": {
                        "type": "string",
                        "minLength" : 1
                      }
                    }
                  },
                "required": ["spl"]
                }
              }
            }
          }
        },
        "then": {
          "properties": {
              "logsource": {
                "required": ["index","source_type"]  
              }
          }
        }
      },
      {
        "if": {
          "properties": {
            "detection": {
              "type": "object",
              "properties": {
                "alert": {
                  "type": "object",
                  "properties": {
                    "sentinel": {
                      "type": "string",
                      "minLength" : 1
                    }
                  },
                "required": ["sentinel"]
                }
              }
            }
          }
        },
        "then": {
          "properties": {
              "logsource": {
                "required": ["connectorId","dataTypes"]
              }
          }
        }
      }
    ],
    "required": ["title","uuid","type","status","priority","description","author","detection","tags"]
}

;

const uiSchema = {
    description: {
      "ui:widget": "textarea"
    },
    detection: {
     alert: {
        spl:{
            "ui:widget": "textarea"
            },
        sentinel:{
            "ui:widget": "textarea"
            },
        cbc:{
            "ui:widget": "textarea"
            },
        ids:{
            "ui:widget": "textarea"
            }                             
      }
    },
    test: {
    description : {
      "ui:field": "collapsible",
      "collapse": {
        "field": "table"}
    }
    }
  };
  
const formData= {
    "uuid": uuidv4()
    };



class App extends Component {
  constructor(props) {
    super(props);
    // initialize state with Simple data sample
    this.state = {
      schema,
      formData,
      uiSchema
    };
  };

  onFormDataChange = ({ formData }) => {
    this.setState({ formData });
  };

  onSubmit = ({ formData }) => {
    this.setState({ formData });
  };
  
  render() {
    const { schema, uiSchema, formData } = this.state;

    return (
     <div className="row">
     <div>{this.state.data}</div>
        <div className="column">
            <Form
              schema={schema}
              formData={formData}
              onChange={this.onFormDataChange}
              onBlurSubmit={this.onSubmit}
              onSubmit={this.onSubmit}
              uiSchema={uiSchema}
            />
        </div>
       <div className="scolumn">
          <h1> Yaml file </h1>
          <pre>{YAML.safeDump(formData,{'sortKeys':false, 'skipInvalid':true})}</pre>
       </div>
     </div>
    );
  }   
 }

export default App;

