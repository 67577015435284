import { StrictMode } from "react";
import ReactDOM from "react-dom";
import './index.css';

import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
    <App />,
  rootElement
);
